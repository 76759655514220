<template>
  <v-layout column align-center justify-center>
    <DefaultTitle class="mb-2">
      {{ `Wizyta ${value.count}` }}
    </DefaultTitle>
    <DefaultSubtitle class="mt-1 text-capitalize">
      {{ appointmentDtFormatted }}
    </DefaultSubtitle>
    <DefaultLabel> ({{ appointmentDt }}) </DefaultLabel>
  </v-layout>
</template>

<script>
import moment from "moment";

export default {
  props: {
    value: {},
  },
  computed: {
    appointmentDt() {
      if (!this.value.card || !this.value.card.date_time) {
        return "";
      }
      return this.value.card.date_time;
    },
    appointmentDtFormatted() {
      return moment(this.appointmentDt).format("dddd, D MMMM YYYY, HH:mm");
    },
  },
  components: {
    DefaultSubtitle: () => import("@/components/text/DefaultSubtitle"),
    DefaultLabel: () => import("@/components/text/DefaultLabel"),
    DefaultTitle: () => import("@/components/text/DefaultTitle"),
  },
};
</script>